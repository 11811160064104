import { ColorPicker } from 'antd';
import { ColorPickerFieldModel, FieldProps } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnColorPickerProps extends ColorPickerFieldModel, FieldProps {}

export const AcnColorPicker = ({
  name,
  label,
  error,
  onChange,
  hidden,
  value,
  disabled,
  note,
  rules,
  hiddenLabel,
}: AcnColorPickerProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <ColorPicker allowClear value={value} disabled={disabled} onChange={(value, hex) => onChange(value)} />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
