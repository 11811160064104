import { createContext, useContext } from 'react';
import { createStore, useStore as useZustandStore } from 'zustand';

export type Mode = 'light' | 'dark';

export type AppStore = {
  mode: Mode;
  loading: boolean;
};

type AppStoreFunction = {
  setLoading: (loading: boolean) => void;
  setMode: (mode: Mode) => void;
};

export type AppStoreInterface = AppStore & AppStoreFunction;

export type StoreType = ReturnType<typeof initializeStore>;

const getDefaultInitialState = (): AppStore => ({
  mode: 'light',
  loading: false,
});

const zustandContext = createContext<StoreType | null>(null);

export const Provider = zustandContext.Provider;

export const useStore = <T>(selector: (state: AppStoreInterface) => T) => {
  const store = useContext(zustandContext);

  if (!store) throw new Error('Store is missing the provider');

  return useZustandStore(store, selector);
};

export const initializeStore = (preloadedState: Partial<AppStoreInterface> = {}) => {
  return createStore<AppStoreInterface>((set, get) => ({
    ...getDefaultInitialState(),
    ...preloadedState,
    setLoading: (loading) => {
      set({
        loading,
      });
    },
    setMode: (mode) => {
      set({ mode });
    },
  }));
};
