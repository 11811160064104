import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

interface AcnLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
}

export const AcnLink = ({ ...props }: AcnLinkProps) => {
  return <Link {...props} />;
};
