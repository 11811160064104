import { InputNumber } from 'antd';
import { FieldProps, InputNumberFieldModel } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnInputNumberProps extends InputNumberFieldModel, FieldProps {}

export const AcnInputNumber = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  value = 0,
  hidden,
  disabled,
  note,
  rules,
  min = 0,
  max,
  size = 'middle',
  controls = false,
  hiddenLabel,
}: AcnInputNumberProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <InputNumber
          className="w-full"
          min={min}
          max={max}
          name={name}
          size={size}
          value={value}
          onChange={(value) => onChange(value)}
          disabled={!!disabled}
          placeholder={placeholder}
          controls={controls}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
