import { Switch } from 'antd';
import { FieldProps, ToggleFieldModel } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnToggleProps extends ToggleFieldModel, FieldProps {}

export const AcnToggle = ({
  name,
  label,
  error,
  onChange,
  hidden,
  value,
  disabled,
  note,
  rules,
  checkedChildren,
  unCheckedChildren,
  hiddenLabel,
}: AcnToggleProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Switch
          onChange={(checked) => onChange(checked)}
          disabled={!!disabled}
          checked={!!value}
          className="ml-3"
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
