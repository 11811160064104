import { notification } from 'antd';
import dayjs from 'dayjs';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { get, isEmpty, isNumber, lowerCase, replace } from 'lodash';
import type { GetServerSidePropsContext } from 'next';
import cookie from 'react-cookies';
import { v4 as uuidv4 } from 'uuid';

export const getCookies = (ctx: GetServerSidePropsContext, name: string) => {
  return ctx.req.cookies[name];
};

export const getCookie = (name: string, options: any = {}) => {
  return cookie.load(name, options);
};

export const decodeToken = (token: string) => {
  return jwt.decode(token);
};

export const setCookie = (name: string, value: any, options: any = {}) => {
  cookie.save(name, value, {
    ...options,
    path: '/',
    expires: dayjs().add(30, 'day').toDate(),
    secure: true,
    sameSite: true,
  });
};

export const setToken = (name: string, value: string, options: any = {}) => {
  const token = decodeToken(value) as JwtPayload;
  if (token) {
    cookie.save(name, value, {
      ...options,
      path: '/',
      expires: new Date((token.exp ?? 3600) * 1000),
      secure: true,
      sameSite: true,
    });
  }
};

export const showErrorNoti = (message: string) => {
  notification.error({ message, className: '[&_.ant-notification-notice-message]:whitespace-break-spaces' });
};

export const showSuccessNoti = (message: string) => {
  notification.success({ message });
};

export const withAuth = <T>(data: T) => {
  return { ...data, hasAuthen: true };
};

export const excludeEmpty = <T extends { [key: string]: any }>(data: T) => {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (isEmpty(value) && !isNumber(value)) {
      delete data[key];
    }
  });

  return data;
};

/**
 * Convert string
 * @param string: tiếng việt
 * @return string: tieng viet
 */
export const vietDecode = (str: string) => {
  try {
    str = lowerCase(str);

    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/[^a-zA-Z0-9\s]/g, '');
    str = str.replace(/\s+/g, ' ');

    return str;
  } catch (e) {
    return null;
  }
};

/**
 * Url SEO
 * @param string vietDecode(a b c)
 * @return a-b-c
 */
export const urlSeo = (str: string) => {
  try {
    const decodeStr = vietDecode(str);
    if (!decodeStr) return null;
    return replace(decodeStr, /\s/g, '-');
  } catch (e) {
    return null;
  }
};

export const slugGenerate = (str: string) => {
  const decodeStr = vietDecode(str);
  if (!decodeStr) return null;
  return urlSeo(decodeStr);
};

export const getUuidv4 = () => {
  return uuidv4();
};

export const getSeoTitle = (details: any, settings: any) => {
  const defaultTitle = 'Giftcode Games';
  let title = get(details, 'title') || get(details, 'seoTitle');

  if (title) return title + ' - ' + get(settings, 'seoTitle', defaultTitle);
  return get(settings, 'seoTitle', defaultTitle);
};

export const getSeoDescription = (details: any, settings: any) => {
  const defaultDescription = 'Tổng hợp giftcode, code game mới nhất, cập nhật liên tục';
  let description =
    get(details, 'description') && get(details, 'description') !== 'Comming soon'
      ? get(details, 'description')
      : get(details, 'seoDescription');

  if (description) return description + ' - ' + get(settings, 'seoDescription', defaultDescription);
  return get(settings, 'seoDescription', defaultDescription);
};

export const serverRedirectTo = (destination: string) => {
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
};
