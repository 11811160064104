import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputType } from './inputType';
import { FieldModel } from './types';

type AutoFormProps<P> = {
  model: P | null;
  fields?: (keyof P)[];
  excludeFields?: (keyof P)[];
  setModel: (model: any) => void;
};

export const AutoForm = <P extends Record<string, any>>({
  model,
  fields,
  setModel,
  excludeFields,
}: AutoFormProps<P>) => {
  const { control, getValues, setValue } = useFormContext();

  const handleSideEffect = (field: FieldModel, value: any) => {
    if (!model) return;
    if (!isEmpty(field.sideEffect)) {
      field.sideEffect!.forEach((effectFieldName) => {
        const effectField = model[effectFieldName] as FieldModel;
        if (!effectField) return;

        if (effectField.trigger) {
          // execute trigger function
          const effectFieldValue = model[effectFieldName].trigger(getValues());
          setValue(effectFieldName, effectFieldValue);
        } else if (!isEmpty(effectField?.dependencies)) {
          // set dependencies is hidden or not
          if (field.name === effectField.dependencies.fieldName) {
            setModel({
              ...model,
              [effectFieldName]: {
                ...model[effectFieldName],
                hidden: effectField.dependencies.value !== value,
              },
            });
          }
        }
      });
    }
  };

  return (
    model &&
    Object.entries(model).map(([fieldName, field]: [string, FieldModel]) => {
      if (field.hidden) return;

      if ((fields && !fields.includes(fieldName)) || excludeFields?.includes(fieldName)) {
        return <React.Fragment key={fieldName}></React.Fragment>;
      }

      return (
        <Controller
          key={fieldName}
          control={control}
          name={fieldName}
          rules={field.rules ?? {}}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const { inputType, ...otherProps } = field;
            if (!inputType) return <></>;
            const InputComponent: any = InputType[inputType];
            return (
              <InputComponent
                {...otherProps}
                value={value}
                onChange={(value: any) => {
                  onChange(value);
                  handleSideEffect(field, value);
                }}
                error={error?.message}
              />
            );
          }}
        />
      );
    })
  );
};

export default React.memo(AutoForm);
