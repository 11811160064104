import React, { useRef, type PropsWithChildren } from 'react';
import type { StoreType } from '../src/base/store/app';
import { Provider, initializeStore } from '../src/base/store/app';

export const StoreProvider = ({ children, ...props }: PropsWithChildren) => {
  const storeRef = useRef<StoreType>();

  if (!storeRef.current) {
    storeRef.current = initializeStore(props);
  }

  return <Provider value={storeRef.current}>{children}</Provider>;
};
