import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { tinyMce } from '../../constants';
import { EditorFieldModel, FieldProps } from '../types';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnEditorProps extends EditorFieldModel, FieldProps {}

export const AcnEditor = ({
  name,
  label,
  error,
  onChange,
  value = '',
  hidden,
  disabled,
  note,
  rules,
  hiddenLabel,
}: AcnEditorProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [mceEditor, setEditor] = useState<any>({});
  const required = rules?.hasOwnProperty('required');
  const [key] = useState(uuidv4());
  const [darkMode] = useLocalStorage({
    key: 'darkMode',
  });

  useEffect(() => {
    changeSkin();
  }, [darkMode]);

  const changeSkin = () => {
    const iframe = document.querySelector('iframe');
    if (iframe?.contentDocument?.body)
      iframe.contentDocument.body.style.backgroundColor = darkMode ? '#141414' : '#fff';
    if (iframe?.contentDocument?.body) iframe.contentDocument.body.style.color = darkMode ? '#fff' : '#000';
  };

  const customPlugin = (editor: any) => {
    selectImagePlugin(editor);
  };

  const handleEditorChange = (content: string, editor: any) => {
    onChange(content);
  };

  const selectImagePlugin = (editor: any) => {
    editor.ui.registry.addButton('selectImages', {
      text: 'Select images',
      tooltip: 'Select images',
      onAction: () => openUpload(editor),
    });
  };

  const openUpload = (editor: any) => {
    open();
    setEditor(editor);
  };

  const handleClose = () => {
    close();
  };

  const handleInsert = (image: string) => {
    let content = '';
    content += `<img src='${image}' alt='image alt' title='image title'/>`;
    mceEditor.insertContent(content);
    close();
  };

  const init = {
    height: 500,
    plugins: tinyMce.plugins,
    toolbar: tinyMce.toolbar,
    image_title: true,
    branding: false,
    setup: customPlugin,
    language: 'vi',
    language_url: '/tinymce/langs/vi.js',
  };

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Editor
          key={key}
          apiKey={tinyMce.key}
          value={value}
          init={init}
          disabled={disabled}
          onEditorChange={handleEditorChange}
          onInit={changeSkin}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
        {/* {opened && <FileManager open={opened} onClose={handleClose} onInsert={handleInsert} />} */}
      </div>
    )
  );
};
