import { CopyOutlined } from '@ant-design/icons';
import { cn, showSuccessNoti } from '@pkg/utils';
import { Input } from 'antd';
import { useTrans } from '../../hooks';
import { FieldProps, InputCopyFieldModel } from '../types';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnInputCopyProps extends InputCopyFieldModel, FieldProps {}

export const AcnInputCopy = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  value = '',
  hidden,
  disabled,
  maxLength,
  note,
  rules,
  prefix,
  className,
  size = 'middle',
  hiddenLabel,
}: AcnInputCopyProps) => {
  const { t } = useTrans();
  const required = !!rules?.hasOwnProperty('required');

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    showSuccessNoti(t('copied'));
  };

  return (
    !hidden && (
      <div key={name} className="space-y-1">
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Input
          name={name}
          size={size}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          prefix={prefix}
          maxLength={maxLength}
          disabled={!!disabled}
          placeholder={placeholder ? t(placeholder) : undefined}
          className={cn(className)}
          suffix={<CopyOutlined onClick={() => handleCopy(value)} />}
          count={{ show: !!maxLength, max: maxLength }}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
