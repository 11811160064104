import { Tooltip } from 'antd';
import { PropsWithChildren } from 'react';

interface AcnTooltipProps extends PropsWithChildren {
  title: string;
  position?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
}

export const AcnTooltip = ({ title, position = 'top', children }: AcnTooltipProps) => {
  return (
    <Tooltip placement={position} title={title} arrow={true}>
      <>{children}</>
    </Tooltip>
  );
};
