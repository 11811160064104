import { useTrans } from '../../..';

type FieldErrorProps = {
  error: string | boolean | undefined;
};

export const FieldError = ({ error }: FieldErrorProps) => {
  const { t } = useTrans();
  return !!error && <div className="text-xs text-red-600">{t(error as string)}</div>;
};
