import { cn } from '@pkg/utils';
import { Divider, DividerProps } from 'antd';

interface AcnDividerProps extends DividerProps {
  className?: string;
}

export const AcnDivider = (props: AcnDividerProps) => {
  return (
    <Divider className={cn('', props.className)} {...props}>
      {props.children}
    </Divider>
  );
};
