import { cn } from '@pkg/utils';
import { Input } from 'antd';
import { useTrans } from '../../hooks';
import { FieldProps, PasswordFieldModel } from '../types';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnInputPasswordProps extends PasswordFieldModel, FieldProps {}

export const AcnInputPassword = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  value = '',
  hidden,
  disabled,
  maxLength,
  note,
  rules,
  prefix,
  className,
  size = 'middle',
  suffix,
  hiddenLabel,
}: AcnInputPasswordProps) => {
  const { t } = useTrans();
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div key={name} className="space-y-1">
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Input.Password
          name={name}
          size={size}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          prefix={prefix}
          maxLength={maxLength}
          disabled={!!disabled}
          placeholder={placeholder ? t(placeholder) : undefined}
          className={cn(className)}
          suffix={suffix}
          count={{ show: !!maxLength, max: maxLength }}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
