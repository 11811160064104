import { cn } from '@pkg/utils';
import { ReactNode } from 'react';
import Nestable, { NestableProps } from 'react-nestable';
import 'react-nestable/dist/styles/index.css';

interface AcnNestableProps extends NestableProps {}
export interface NestableRenderItem {
  item: Record<string, any>;
  index: number;
  depth: number;
  isDraggable: boolean;
  collapseIcon: ReactNode;
  handler: ReactNode;
}

export const AcnNestable = (props: AcnNestableProps) => {
  return (
    <Nestable
      {...props}
      className={cn(
        '[&_.nestable-item-name]:cursor-grab [&_.nestable-item-name]:py-1',
        '[&_.nestable-item-name]:px-2 [&_.nestable-item-name]:border [&_.nestable-item-name]:borer-solid',
        '[&_.nestable-item-name]:border-stone-300 dark:[&_.nestable-item-name]:border-stone-700'
      )}
    />
  );
};
