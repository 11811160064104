import { SelectOption } from '@pkg/constants';
import { cn } from '@pkg/utils';
import { Select } from 'antd';

type AcnSelectProps = {
  onChange: (value: string | number) => void;
  value: string | number | undefined;
  className?: string;
  options: SelectOption[];
};

export const AcnSelect = ({ onChange, value, className, options }: AcnSelectProps) => {
  return <Select value={value} className={cn(className)} onChange={onChange} options={options} />;
};
