import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useDisclosure } from '@mantine/hooks';
import { Upload } from 'antd';
import { isEmpty } from 'lodash';
import { MouseEvent, useState } from 'react';
import { AcnImage } from '../../components';
import { useTrans } from '../../hooks';
import { FieldProps, MultipleImageFieldModel } from '../types';
import { FieldError, FieldLabel, FieldNote } from './components';

const buttonStyle = {
  width: '100%',
  height: '100%',
  padding: '30px 0',
};

export interface AcnMultipleImageProps extends MultipleImageFieldModel, FieldProps {}

export const AcnMultipleImage = ({
  hidden,
  name,
  label,
  error,
  onChange,
  value = [],
  note,
  rules,
  hiddenLabel,
}: AcnMultipleImageProps) => {
  const { t } = useTrans();
  const [opened, { open, close }] = useDisclosure(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const required = rules?.hasOwnProperty('required');

  const openFileManager = (e: MouseEvent<Element> | null) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    open();
    setUploadLoading(true);
  };

  const uploadButton = () => {
    return (
      <div style={buttonStyle} onClick={(e) => openFileManager(e)} key={name}>
        {uploadLoading ? (
          <LoadingOutlined onClick={() => openFileManager(null)} />
        ) : (
          <PlusOutlined onClick={(e) => openFileManager(e)} />
        )}
        <div className="ant-upload-text" onClick={(e) => openFileManager(e)}>
          {t('uploadImageBtn')}
        </div>
      </div>
    );
  };

  const handleClose = () => {
    close();
    setUploadLoading(false);
  };

  const handleInsert = (image: string[]) => {
    onChange(image);
    setUploadLoading(false);
    close();
  };

  if (hidden) return <></>;

  return (
    <div className="mb-4 space-y-1" key={name}>
      <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
      <Upload
        key={name}
        name={name}
        listType="picture-card"
        className="avatar-uploader"
        openFileDialogOnClick={false}
        showUploadList={false}
      >
        {uploadButton()}
        {!isEmpty(value) && (
          <div style={{ display: 'flex', gap: '10px', padding: '10px', flexFlow: 'wrap' }}>
            {value.map((r) => {
              return (
                <AcnImage
                  key={r}
                  src={r}
                  width={100}
                  height={100}
                  alt="Gallery image"
                  onClick={(e) => openFileManager(e)}
                  className="w-[100px] h-[100px] border border-solid border-gray-300"
                />
              );
            })}
          </div>
        )}
      </Upload>
      <FieldError error={error} />
      <FieldNote {...note} />
      {/* {opened && <FileManager open={opened} multiple={true} onClose={handleClose} onInsert={handleInsert} />} */}
    </div>
  );
};
