import { StoreProvider } from '@pages/admin/storeProvider';
import { LanguageProvider } from '@pkg/ui';
import { cn } from '@pkg/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getCookies } from 'cookies-next';
import Head from 'next/head';
import '../globals.css';
import '../src/languages/config';

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: any) {
  return (
    <div
      id="app-wrapper"
      className={cn(
        '',
        { 'bg-black text-white': pageProps?.initializeStore.mode === 'dark' },
        pageProps?.initializeStore.mode
      )}
    >
      <Head>
        <title>Admin</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" href="/favicon.svg" />
      </Head>
      <StoreProvider {...pageProps?.initializeStore}>
        <LanguageProvider>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
          </QueryClientProvider>
        </LanguageProvider>
      </StoreProvider>
    </div>
  );
}

App.getInitialProps = async (ctx) => {
  const cookies = getCookies(ctx.ctx);
  return { pageProps: { initializeStore: { mode: cookies.darkMode === 'true' ? 'dark' : 'light' } } };
};
