import { readLocalStorageValue } from '@mantine/hooks';
import { AccessTokenPayload, User } from '@models/base';
import { userToken } from '@pkg/constants';
import { decodeToken } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { fetchData, useUser } from '..';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const router = useRouter();
  const { user, setUser } = useUser();
  const token = readLocalStorageValue({ key: userToken });

  useQuery<User | undefined>({
    queryKey: ['user'],
    queryFn: async () => {
      const token = localStorage.getItem(userToken);
      if (token) {
        const tokenData = decodeToken(token) as AccessTokenPayload;
        const res = await fetchData({ endpoint: 'users/:id', hasAuthen: true, query: { id: tokenData._id } });
        if (res.error?.statusCode === 401) {
          localStorage.removeItem(userToken);
          return setUser();
        }
        setUser(res.data);
        return res.data;
      }
    },
    enabled: !!token && !user,
  });

  useEffect(() => {
    const token = localStorage.getItem(userToken);
    if (!token) {
      router.push('/login');
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(userToken);
    if (!token) {
      router.push('/login');
    }
  }, [user]);

  if (!user) return <></>;

  return children;
};
