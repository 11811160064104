import { isString } from 'lodash';
import { ImageProps } from 'next/dist/shared/lib/get-img-props';
// eslint-disable-next-line no-redeclare
import { websiteConfigs } from '@pkg/config';
import Image from 'next/image';

interface AcnImageProps
  extends Pick<
    ImageProps,
    | 'src'
    | 'alt'
    | 'fill'
    | 'width'
    | 'height'
    | 'layout'
    | 'loader'
    | 'quality'
    | 'onError'
    | 'loading'
    | 'priority'
    | 'className'
    | 'unoptimized'
    | 'onLoadingComplete'
  > {
  onClick?: (e: any) => void;
}

export const AcnImage = ({ ...props }: AcnImageProps) => {
  let src = props.src;

  if (isString(props.src) && props.src.indexOf('api') !== -1) {
    src = `${websiteConfigs.apiUrl}${props.src}`;
  }

  return <Image {...props} src={src} />;
};
