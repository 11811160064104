import { cn } from '@pkg/utils';
import { Avatar } from 'antd';

type AcnAvatarProps = {
  src?: string;
  text?: string;
  className?: string;
};

export const AcnAvatar = ({ src, text = '', className }: AcnAvatarProps) => {
  if (src) return <Avatar className={cn(className)} src />;

  return (
    <Avatar className={cn(className)}>
      {text
        .split(' ')
        .map((r) => r.charAt(0).toUpperCase())
        .join('')}
    </Avatar>
  );
};
