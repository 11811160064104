import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useLocale } from '..';

export const useTrans = () => {
  const router = useRouter();
  const { locale } = useLocale();

  const { t } = useTranslation('translation', { lng: locale || router.locale });
  return { t };
};
