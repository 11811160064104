import { cn } from '@pkg/utils';
import { Menu } from 'antd';
import { ReactNode } from 'react';
import { AcnLink } from './Link';

export type MenuItem = {
  key: string;
  slug?: string;
  icon?: ReactNode;
  label: string | ReactNode;
  children?: MenuItem[];
};

type AcnMenuProps = {
  selectedKey?: string[];
  defaultOpenKeys?: string[];
  items: MenuItem[];
  className?: string;
};

export const AcnMenu = (props: AcnMenuProps) => {
  return (
    <Menu
      mode="inline"
      selectedKeys={props.selectedKey}
      className={cn('h-[calc(100vh-95px)]', props.className)}
      defaultOpenKeys={props.defaultOpenKeys}
      items={props.items.map((item) => {
        item.label = item.slug ? <AcnLink href={item.slug}>{item.label}</AcnLink> : item.label;
        if (item.children) {
          item.children = item.children.map((sub) => {
            sub.label = sub.slug ? <AcnLink href={sub.slug}>{sub.label}</AcnLink> : sub.label;
            return sub;
          });
        }
        return item;
      })}
    />
  );
};
