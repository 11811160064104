import { create } from 'zustand';

export type LocaleStore = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const useLocaleStore = create<LocaleStore>((set) => ({
  locale: '',
  setLocale: (locale: string) => set((state) => ({ locale })),
}));

export const useLocale = () => {
  const state = useLocaleStore((state) => state);
  const { locale, setLocale } = state;

  return { locale, setLocale };
};
