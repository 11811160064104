import { cn } from '@pkg/utils';
import { Button } from 'antd';
import { ReactNode } from 'react';

type AcnButtonProps = {
  href?: string;
  icon?: ReactNode;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  size?: 'small' | 'middle' | 'large';
  type?: 'link' | 'text' | 'default' | 'primary' | 'dashed';
  loading?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
};

export const AcnButton = ({
  type,
  size,
  href,
  icon,
  onClick,
  loading,
  children,
  disabled,
  htmlType,
  className,
}: AcnButtonProps) => {
  return (
    <Button
      type={type}
      size={size}
      href={href}
      icon={icon}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      className={cn('', className)}
      children={children}
    />
  );
};
