type FieldNoteProps = {
  label?: string;
  text?: string;
};

export const FieldNote = ({ label, text }: FieldNoteProps) => {
  return (
    label &&
    text && (
      <div className="note text-xs italic text-gray-500 pt-1">
        {label}: <span dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    )
  );
};
