import { Spin } from 'antd';
import { PropsWithChildren } from 'react';

interface AcnSpinProps extends PropsWithChildren {
  spinning?: boolean;
}

export const AcnSpin = ({ children, spinning = true }: AcnSpinProps) => {
  return (
    <Spin spinning={spinning} className="!max-h-screen">
      {children}
    </Spin>
  );
};
