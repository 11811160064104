import { cn } from '@pkg/utils';
import { Radio } from 'antd';
import { FieldProps, RadioFieldModel } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnRadioProps extends RadioFieldModel, FieldProps {}

export const AcnRadio = ({
  name,
  error,
  onChange,
  values,
  value,
  equal,
  hidden,
  disabled,
  label,
  note,
  rules,
  hiddenLabel,
}: AcnRadioProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Radio.Group
          name={name}
          options={values}
          disabled={disabled}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          className={cn({ flex: equal, block: !equal })}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
