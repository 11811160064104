import { AcnCheckbox, AcnCheckboxProps } from './Checkbox';
import { AcnColorPicker, AcnColorPickerProps } from './ColorPicker';
import { AcnDatePicker, AcnDatePickerProps } from './DatePicker';
import { AcnDropdown, AcnDropdownProps } from './Dropdown';
import { AcnEditor, AcnEditorProps } from './Editor';
import { AcnImageProps, Image } from './Image';
import { AcnInput, AcnInputProps } from './Input';
import { AcnInputCopy, AcnInputCopyProps } from './InputCopy';
import { AcnInputCurrency, AcnInputCurrencyProps } from './InputCurrency';
import { AcnInputNumber, AcnInputNumberProps } from './InputNumber';
import { AcnMultipleImage, AcnMultipleImageProps } from './MultipleImage';
// import MemberEditor from './memberEditor';
// import MultipleUpload from './multipleUpload';
import { AcnInputPassword, AcnInputPasswordProps } from './Password';
// import Permission from './permission';
import { AcnRadio, AcnRadioProps } from './Radio';
import { AcnRate, AcnRateProps } from './Rate';
import { AcnTag, AcnTagProps } from './Tag';
import { AcnTextArea, AcnTextAreaProps } from './Textarea';
import { AcnToggle, AcnToggleProps } from './Toggle';
// import TreeSelect from './treeSelect';
// import Upload from './upload';

export const InputType = {
  Input: (props: AcnInputProps) => <AcnInput {...props} />,
  TextArea: (props: AcnTextAreaProps) => <AcnTextArea {...props} />,
  Checkbox: (props: AcnCheckboxProps) => <AcnCheckbox {...props} />,
  Radio: (props: AcnRadioProps) => <AcnRadio {...props} />,
  Dropdown: (props: AcnDropdownProps) => <AcnDropdown {...props} />,
  // Permission: (props: any) => <Permission {...props} />,
  Editor: (props: AcnEditorProps) => <AcnEditor {...props} />,
  Password: (props: AcnInputPasswordProps) => <AcnInputPassword {...props} />,
  // TreeSelect: (props: any) => <TreeSelect {...props} />,
  Image: (props: AcnImageProps) => <Image {...props} />,
  MultipleImage: (props: AcnMultipleImageProps) => <AcnMultipleImage {...props} />,
  InputCopy: (props: AcnInputCopyProps) => <AcnInputCopy {...props} />,
  Tag: (props: AcnTagProps) => <AcnTag {...props} />,
  Toggle: (props: AcnToggleProps) => <AcnToggle {...props} />,
  ColorPicker: (props: AcnColorPickerProps) => <AcnColorPicker {...props} />,
  InputNumber: (props: AcnInputNumberProps) => <AcnInputNumber {...props} />,
  InputCurrency: (props: AcnInputCurrencyProps) => <AcnInputCurrency {...props} />,
  // Upload: (props: any) => <Upload {...props} />,
  // MultipleUpload: (props: any) => <MultipleUpload {...props} />,
  DatePicker: (props: AcnDatePickerProps) => <AcnDatePicker {...props} />,
  // MemberEditor: (props: any) => <MemberEditor {...props} />,
  Rate: (props: AcnRateProps) => <AcnRate {...props} />,
};
