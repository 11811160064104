import { Rate } from 'antd';
import { FieldProps, RateFieldModel } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnRateProps extends RateFieldModel, FieldProps {}

export const AcnRate = ({ name, error, onChange, value, hidden, label, note, rules, hiddenLabel }: AcnRateProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        {note && (
          <div className="text-xs italic text-blue-500 note">
            {note.label}: <span dangerouslySetInnerHTML={{ __html: note.text }} />
          </div>
        )}
        <Rate className="w-full" data-name={name} value={value} onChange={(value) => onChange(value)} />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};

export default Rate;
