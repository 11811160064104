import { isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldModel } from './types';

type RawModelFunction<T, P> = (props: P) => T;

export type AutoFormModelResult<T> = {
  model: T | null;
  setModel: Dispatch<SetStateAction<T | null>>;
};

export const useFormModel = <T extends Record<string, any>, P>(
  rawModel: RawModelFunction<T, P>,
  modelProps: P
): AutoFormModelResult<T> => {
  const [model, setModel] = useState<T | null>(rawModel(modelProps));

  useEffect(() => {
    const generatedModel: T = rawModel(modelProps);
    Object.entries(generatedModel).map(([fieldName, field]: [string, FieldModel]) => {
      // set dependencies is hidden or not
      if (!isEmpty(field.sideEffect)) {
        field.sideEffect!.forEach((fieldEffectName) => {
          const effectField = generatedModel[fieldEffectName] as FieldModel;
          if (field.name === effectField?.dependencies?.fieldName) {
            effectField.hidden = effectField.dependencies && effectField.dependencies.value !== field.value;
          }
        });
      }
    });
    setModel(generatedModel);
  }, []);

  return { model, setModel };
};

export const getModelValues = <T, P>(rawModel: RawModelFunction<T, P>) => {
  const model: any = rawModel({} as P);
  const formValue: Partial<Record<keyof T, any>> = {};
  Object.entries(model).map(([key, field]) => {
    formValue[key as keyof T] = (field as any).value;
  });
  return formValue;
};

export const MemoizedFormModel = React.memo<any>(useFormModel);
