import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useDisclosure } from '@mantine/hooks';
import { Upload } from 'antd';
import { MouseEvent, useState } from 'react';
import { AcnImage } from '../../components';
import { useTrans } from '../../hooks';
import { FieldProps, ImageFieldModel } from '../types';
import { FieldError, FieldLabel, FieldNote } from './components';

const buttonStyle = {
  width: '100%',
  height: '100%',
  padding: '30px 0',
};

export interface AcnImageProps extends ImageFieldModel, FieldProps {}

export const Image = ({ hidden, name, label, error, onChange, value, note, rules, hiddenLabel }: AcnImageProps) => {
  const { t } = useTrans();
  const [opened, { open, close }] = useDisclosure(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const required = !!rules?.hasOwnProperty('required');

  const openFileManager = (e: MouseEvent<Element> | null) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
    open();
    setUploadLoading(true);
  };

  const uploadButton = () => {
    return (
      <div style={buttonStyle} onClick={(e) => openFileManager(e)} key={name}>
        {uploadLoading ? (
          <LoadingOutlined onClick={() => openFileManager(null)} />
        ) : (
          <PlusOutlined onClick={(e) => openFileManager(e)} />
        )}
        <div className="ant-upload-text" onClick={(e) => openFileManager(e)}>
          {t('uploadImageBtn')}
        </div>
      </div>
    );
  };

  const handleClose = () => {
    close();
    setUploadLoading(false);
  };

  const handleInsert = (image: string) => {
    onChange(image);
    setUploadLoading(false);
    close();
  };

  if (hidden) return <></>;

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Upload
          key={name}
          name={name}
          listType="picture-card"
          className="avatar-uploader"
          openFileDialogOnClick={false}
          showUploadList={false}
        >
          {value ? (
            <AcnImage
              src={value}
              className="w-full"
              width={100}
              height={100}
              alt="Thumbnail Image"
              onClick={(e: any) => openFileManager(e)}
            />
          ) : (
            uploadButton()
          )}
        </Upload>
        <FieldError error={error} />
        <FieldNote {...note} />
        {/* {opened && <FileManager open={open} multiple={multiple} onClose={handleClose} onInsert={handleInsert} />} */}
      </div>
    )
  );
};
