import { Table, TableColumnsType, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { Key } from 'antd/es/table/interface';

export type OnChange = NonNullable<TableProps<any>['onChange']>;

type AcnDataTableProps<T> = {
  size?: 'small' | 'middle' | 'large';
  data: T[];
  columns: TableColumnsType<T>;
  rowsSelected?: string[];
  onSelected?: (keys: Key[]) => void;
  disableScrollY?: boolean;
  onChange?: OnChange;
};

export const AcnDataTable = <T extends AnyObject>({
  size = 'small',
  data,
  columns,
  rowsSelected,
  onSelected,
  disableScrollY,
  onChange,
}: AcnDataTableProps<T>) => {
  const rowSelection = {
    selectedRowKeys: rowsSelected,
    onChange: (selectedRowKeys: Key[], selectedRows: T[]) => {
      onSelected?.(selectedRowKeys);
    },
  };

  return (
    <Table
      bordered
      size={size}
      columns={columns}
      dataSource={data}
      pagination={false}
      onChange={onChange}
      className="[&_img]:w-12"
      rowSelection={onSelected ? rowSelection : undefined}
      rowKey={(record) => record._id || record.name || record.code}
      scroll={{ y: !disableScrollY ? 'calc(100vh - 319px)' : undefined, x: `calc(${columns?.length} * 150px)` }}
    />
  );
};
