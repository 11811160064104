import { cn } from '@pkg/utils';
import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

type AcnCopyProps = {
  content: string;
  className?: string;
};

export const AcnCopy = ({ content, className }: AcnCopyProps) => {
  return (
    <Paragraph copyable className={cn('!mb-0', className)}>
      {content}
    </Paragraph>
  );
};
