import { datePickerFormat } from '@pkg/constants';
import { DatePicker as ADatePicker } from 'antd';
import dayjs from 'dayjs';
import { DatePickerFieldModel, FieldProps } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnDatePickerProps extends DatePickerFieldModel, FieldProps {}

export const AcnDatePicker = ({
  name,
  placeholder,
  label,
  error,
  onChange,
  value = null,
  hidden,
  disabled,
  note,
  rules,
  size = 'middle',
  format = datePickerFormat,
  autoComplete,
  hiddenLabel,
}: AcnDatePickerProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4 space-y-1" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <ADatePicker
          disabled={disabled}
          size={size}
          value={value ? dayjs(value, format) : null}
          format={format}
          placeholder={placeholder}
          className="w-full"
          onChange={(date, dateString) => onChange(dateString)}
          autoComplete={autoComplete}
        />
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
