import { Tag } from 'antd';
import { FieldProps, TagFieldModel } from '..';
import { FieldLabel } from './components';

export interface AcnTagProps extends TagFieldModel, FieldProps {}

export const AcnTag = ({ label, value = '', color = 'blue', hiddenLabel }: AcnTagProps) => {
  return (
    <>
      <FieldLabel label={label} hiddenLabel={hiddenLabel} />
      {value && (
        <Tag className="ml-3" color={color}>
          {value}
        </Tag>
      )}
    </>
  );
};
