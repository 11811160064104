import { excludeEmpty } from '@pkg/utils';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import urlcat from 'urlcat';

export const useQueryFilter = <T extends Record<string, any>>(searchTarget: string, initData?: Partial<T>) => {
  const router = useRouter();
  const { query, pathname } = router;

  const filter = useMemo(() => {
    return { ...(initData || {}), ...query } as T;
  }, [pathname, query]);

  const setFilter = useCallback(
    (data: Partial<T>, overwrite = false) => {
      const cleanData = excludeEmpty(overwrite ? data : { ...filter, ...data });
      const url = urlcat('/:searchTarget', { searchTarget, ...cleanData });
      router.push(url);
    },
    [pathname, initData, filter]
  );

  const clearFilter = useCallback(() => {
    setFilter(initData || {}, true);
  }, [initData]);

  return { filter, setFilter, clearFilter };
};
