import { cn } from '@pkg/utils';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { ReactNode } from 'react';

type AcnMenuDropdownProps = {
  children: ReactNode;
  items: ItemType[];
  className?: string;
};

export const AcnMenuDropdown = ({ children, items, className }: AcnMenuDropdownProps) => {
  return (
    <Dropdown className={cn(className)} menu={{ items }}>
      {children}
    </Dropdown>
  );
};
