import { cn } from '@pkg/utils';
import { PropsWithChildren } from 'react';
import { baseBorderColor } from '../classHelpers';

interface AcnCardProps extends PropsWithChildren {
  className?: string;
  withBorder?: boolean;
}

interface TitleProps extends PropsWithChildren {
  className?: string;
}

interface ContentProps extends PropsWithChildren {
  className?: string;
}

export const AcnCard = ({ children, className, withBorder }: AcnCardProps) => {
  return (
    <div
      className={cn('rounded-sm bg-secondary', className, { [cn(baseBorderColor, 'border border-solid')]: withBorder })}
    >
      {children}
    </div>
  );
};

const Title = ({ children, className }: TitleProps) => {
  return <div className={cn(baseBorderColor, 'border-b px-4 py-3 border-solid', className)}>{children}</div>;
};

const Content = ({ children, className }: ContentProps) => {
  return <div className={cn('p-4', className)}>{children}</div>;
};

AcnCard.Title = Title;
AcnCard.Content = Content;
