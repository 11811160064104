import { User } from "@models/base";
import { get } from "lodash";
import { create } from "zustand";

export type UserStore = {
  user?: User;
  setUser: (user?: User) => void;
};

export const useUserStore = create<UserStore>((set) => ({
  setUser: (user?: User) => set((state) => ({ user })),
}));

export const useUser = () => {
  const state = useUserStore((state) => state);
  const { user, setUser } = state;

  const roles = get(user, "group.roles") || {};

  return { user, setUser, roles };
};
