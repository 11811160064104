import { Checkbox } from 'antd';
import { CheckboxFieldModel, FieldProps } from '..';
import { FieldError, FieldLabel, FieldNote } from './components';

export interface AcnCheckboxProps extends CheckboxFieldModel, FieldProps {}

export const AcnCheckbox = ({
  name,
  label,
  error,
  onChange,
  text,
  hidden,
  value,
  disabled,
  note,
  rules,
  hiddenLabel,
}: AcnCheckboxProps) => {
  const required = !!rules?.hasOwnProperty('required');

  return (
    !hidden && (
      <div className="mb-4" key={name}>
        <FieldLabel label={label} required={required} hiddenLabel={hiddenLabel} />
        <Checkbox name={name} onChange={(e) => onChange(e.target.checked)} disabled={!!disabled} checked={!!value}>
          <label dangerouslySetInnerHTML={{ __html: text }} />
        </Checkbox>
        <FieldError error={error} />
        <FieldNote {...note} />
      </div>
    )
  );
};
