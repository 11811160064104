import { Breadcrumb } from 'antd';
import { useRouter } from 'next/router';

export type BreadcrumbItem = {
  href?: string;
  title: string;
};

type AcnBreadcrumbProps = {
  items: BreadcrumbItem[];
};

export const AcnBreadcrumb = ({ items }: AcnBreadcrumbProps) => {
  const router = useRouter();

  return (
    <Breadcrumb
      items={items.map((r) => ({ ...r, href: '#', onClick: r.href ? () => router.push(r.href!) : undefined }))}
    />
  );
};
