import { ReactNode, useEffect } from 'react';
import { useLocale } from '..';

type LanguageProviderProps = {
  children: ReactNode;
};

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const { locale, setLocale } = useLocale();

  useEffect(() => {
    const lc = localStorage.getItem('locale');
    if (lc && lc !== locale) setLocale(lc);
  }, []);

  return children;
};
