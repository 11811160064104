import { useDisclosure } from '@mantine/hooks';
import { showErrorNoti } from '@pkg/utils';
import { useEffect } from 'react';

type UseSkeletonProps = {
  isSuccess: boolean;
  error: Error | null;
};

export const useSkeleton = ({ isSuccess, error }: UseSkeletonProps) => {
  const [finished, { open }] = useDisclosure(false);

  useEffect(() => {
    if (error) {
      showErrorNoti(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        open();
      }, 100);
    }
  }, [isSuccess]);

  return { finished };
};
